module.exports = {
    seo: {
        defaultTitle: `Marmelab - Digital Innovation Studio`,
        defaultDescription: `We use digital technologies and agile methodologies to make the world a better place.`,
        blogTitle: 'The marmelab blog',
        blogDescription: `Articles, tutorials, project feedbacks: read all about marmelab's activity on Agile project management, Lean startup, Angular and Backbone development, Gaudi and Docker, Symfony2, PHPCR, and much more!`,
        jobTitle: 'Marmelab - Join Us',
        jobDescription: `Come and work at marmelab, where you will find talented developers and agile coaches, as well as exciting innovation projects and cutting-edge technology.`,
        showcasesTitle: 'Marmelab - Showcase',
        showcasesDescription: `Our customers’ common need: innovation. Major accounts and startups, let’s work together.`,
        valuesTitle: 'Marmelab - Our values',
        valuesDescription:
            'Our choices to make the world a better place using digital innovation',
        organizationTitle: 'Marmelab - Our teams',
        organizationDescription: "The marmelab teams'organization",
    },
    home: {
        tagline:
            'We use digital technologies and agile methodologies to make the world a better place.',
    },
    menu: {
        home: 'Home',
        showcase: 'Showcase',
        jobs: 'Jobs',
        blog: 'Blog',
        values: 'Values',
        menu: 'Menu',
    },
    title: {
        blog: 'Marmelab Blog',
    },
    aboutus: {
        title: 'What We Do',
        learn_more: 'Read more',
        contact_us: 'Contact us',
        innovation: 'Web & Mobile Development',
        innovationContent: `
        <p>We build the apps that you imagine, from design to code, tests, and deployment.
        Our focus is on delivering projects rapidly to allow for frequent iterations and incorporate feedback from users.
        We apply professional web development best practices and deliver secure, fast, robust, and maintainable applications.</p>
        <p>Our development stack, which includes React.js, Svelte, Node.js, APIs, PostgreSQL, and TypeScript,
        seamlessly supports incremental development, from prototyping to large software architectures.</p>
        <p>Our primary objective is to create value throughout the development process, prioritizing the development of products over programs.</p>`,
        innovationExpandableContent1: {
            title: 'Fast Setup',
            content: `<p>Time-to-market is a key factor in innovation. We start actual developments no more than 2 weeks
            after the project starts.</p>
            <p>During this "sprint zero", we apply a proven process inspired by Lean Startup. We frame the requirements, 
            define key success factors, draft personas, lay down the vision of the final product, make the first technical decisions,
            and prioritize the initial user stories.</p>`,
        },
        innovationExpandableContent2: {
            title: 'Iterative developemnt',
            content: `<p>
            We develop in "sprints" made of: </p>
            <ul>
            <li>2 dedicated full-stack developers</li>
            <li>a faciliatator working half-time on the project</li>
            <li>the assistance of an engineering expert</li>
            <li>during 2 weeks</li>
            <li>with a complete software factory</li>
            </ul>
            <p>We work remotely from our offices in France, but we meet our customers every day via video and chat.
            We also book an entire day at the end of every sprint for the demo, retrospective,
            and planning of the next sprint.</p>
            <p>We agree at the beginning of the project on the number of sprints, to make sure the budget
            is respected and the team is booked for the duration of the contract.</p>`,
        },
        innovationExpandableContent3: {
            title: 'Professional Standards',
            content: `<p>Our teams follow rigourous, high-quality standards so that the applications
            we develop are robust, secure and fast.</p>
            <p>Documentation, deployment automation, continuous integration, stress and penetration tests,
            and a realtime business goals dashboard come with every project.</p>
            <p>If you need performance, rest assured: we've already built high-traffic web applications
            (up to 100,000 concurrent users), so this is our bread and butter.</p>`,
        },
        innovationExpandableContent4: {
            title: 'No surprises contract',
            content: `<p>We've been working as IT contractors successfully since 2012, with customers all around the world.
            We develop the code for you, you own it, no legal worries.</p>
            <p>To learn more, wheck our <a href="https://marmelab.com/en/showcase">Showcase</a> page.</p>`,
        },

        development: 'React-admin, the best B2B framework',
        developmentContent: `
        <p>Since 2016, we edit and support <a href="https://marmelab.com/react-admin">React-admin</a>, 
        an open-source, low-code developer tool that accelerates the development of admins, dashboards, and B2B apps.
        Very popular, it powers 3,000 new websites every month.</p>
        <p>React-admin also comes as an Enterprise Edition with additional modules, professional support, and more.
        This solution has already convinced more than 450 companies around the world, enough to make the open-source famework sustainable.</p> `,
        developmentExpandableContent1: {
            title: 'Quick Prototyping',
            content: `<p>React-admin allows to bootstrap a fully working admin on top of an existing API in minutes.
            Developers can then spend time on customizing the user interface and adding new features
            by tweaking the generated code.</p>`,
        },
        developmentExpandableContent2: {
            title: 'Full-Stack',
            content: `<p>From authorization to internationalization, including theming and logs, all the usual requirements
            of B2B applications are covered by react-admin. This means developers can spend time on the value-adding features. 
            As every component in react-admin can be replaced by a custom component, there is no limit to the level of customization
            it offers.</p>`,
        },
        developmentExpandableContent3: {
            title: 'Acclaimed Developer Experience',
            content: `<p>Complete documentation, sophisticated demos with commented code, TypeScript types, 
            a modular architecture, clear and documented code... This all leads to productivity and joy.</p>
            <p>We've learned a lot from our previous open-source projects (we've gathered more than 50,000 stars on GitHub).
            Check our <a href="https://github.com/marmelab">GitHub Profile</a> to see how we share our knowledge.</p>`,
        },
        developmentExpandableContent4: {
            title: 'Professional Support',
            content: `<p>Both Large corporate companies and startups choose react-admin because they trust the sponsoring
            and support of Marmelab, an established and profitable company. The Entreprise Edition makes react-admin 
            self-sufficient, and guarantees that it will be maintained in the long term.</p>`,
        },

        consulting: 'Carbon Footprint Calculator',
        consultingContent: `
        <p>We built <a href="https://greenframe.io">GreenFrame</a>, a SaaS solution that measures the CO2 emissions 
        of any web application, regardless of its client and server technology. By allowing to detect and fix "Carbon Leaks",
        GreenFrame helps developers improve their practices to make them more sustainable.</p>
        <p>GreenFrame is also an essential tool for companies with a CSR ambition, as it is the only service that measures
        "scope 3" emissions of software - the emissions coming from the usage of the service.</p>
        <p>GreenFrame is the proof of our commitment to fight climate change through digital innovation.</p>`,
        consultingExpandableContent1: {
            title: 'Solid Scientific Foundations',
            content: `<p>GreenFrame uses a model built by a researcher from French CNRS - the national institute for scientific research.</p>
            <p>From the browser to the database, including network and web servers, GreenFrame estimates the carbon emissions of the full
            tech stack. Based on user scenarios, it detects variations of the carbon footprint over time.</p>
            <p>More than 18 months of engineering were necessary to tweak the GreenFrame engine, in order to make it produce accurate, reproducible, and 
            usable results.</p>`,
        },
        consultingExpandableContent2: {
            title: 'Carbon Insurance',
            content: `<p>GreenFrame runs as a Continuous Integration (CI) payload. This means that every time a developer makes a change
            to the code, GreenFrame measures the related variations in carbon footprint, and warns the developer
            directly in the Pull Request if it detects a change.</p>
            <p>GreenFrame allows to follow the gains of a Carbon reduction strategy over time, and to validate (or invalidate) the impact
            application changes have on carbon emissions.</p>`,
        },
        consultingExpandableContent3: {
            title: 'Beneficial For Both The Company And Its Customers',
            content: `<p>GreenFrame is a key part of the CSR strategy. By improving their brand image, companies use GreenFrame to get in touch with
            new prospects or candidates.</p>
            <p>GreenFrame helps to save hosting costs, increases transformation rates, and makes users change devices less often.
            What's good for the planet is also good for the business!</p>`,
        },
    },
    viewMoreFromBlog: 'View more from blog',
    wearemaker: {
        title: 'We are makers',
        offices: 'Our offices',
        joinus: 'Join us!',
        tagline:
            'We build innovative digital products for corporate companies & startups',
        text:
            'We love to share our knowledge, to find the best tool for the job in a pragmatic way, and to work together as a team. We’re small, so everyone is important.',
    },
    viewUsOnTwitter: 'View us on twitter',
    viewUsOnGithub: 'View us on github',
    showcases: {
        title: 'What We Do',
        readmore: 'Read',
        view_site: 'Learn more',
        view_project: 'View our project',
        project: 'Project',
        lab_work: 'Lab work',
        product: 'Product',
    },
    readMore: 'Read More',
    latestPosts: 'Latest posts',
    latestPostsBy: 'Latest posts by %{author}',
    noPostsBy: "%{author} hasn't written any posts yet",
    olderPosts: 'Older posts',
    customers: {
        title: 'Our happy customers',
    },
    call_to_action: `Let's work together on your next project!`,
    we_are_marmelab:
        'We are marmelab, a French digital innovation studio, building web & mobile apps for corporate companies and startups since 2013. We are also the creators of <a href="https://marmelab.com/react-admin">react-admin</a> and <a href="https://greenframe.io">greenframe.io</a>.',
    showcase: {
        title: 'Our customers’ common need: innovation',
        tagline: 'Major accounts and startups, let’s work together',
        our_work: 'Our work',
        inside_our_lab: 'Inside our lab',
        inside_our_lab_text_1:
            'Our research and innovation is often open-source, and adds up to more than 80,000 stars on GitHub.',
        inside_our_lab_text_2:
            'To learn more about our latest innovations, go to <a href="https://github.com/marmelab">github.com/marmelab</a>.',
    },
    values: {
        preTitle: 'Our pledge:',
        title: 'Make the world a better place using digital innovation',
        tagline: `Sustainability is the key challenge of our time.<br />
            Because tech can transform how we live, we should use it to be more eco-friendly.<br />
            <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/marmelab/">B Corp Certified</a>, we're authentically ethical and green.`,
        intro: `This vision guides us for all the choices we make in our work.
            See how it applies to all our decisions.`,
        themes: {
            suppliers: 'Suppliers',
            mobility: 'Mobility',
            office: 'Office Life',
            sharing: 'Sharing',
            workers: 'Workers',
            products: 'Products',
            management: 'Management',
        },
        readmore: 'Discover our values',
    },
    organization: {
        preTitle: 'Organization:',
        title: `The marmelab teams'distribution`,
        link: `See personal page`,
        dev: 'Developer',
        facilitator: 'Facilitator',
        ceo: 'CEO',
        sale: 'Sale',
        manyThings: 'Office manager',
    },
    aboutAuthors: 'About the author |||| About the authors',
    workTogether: "Let's work together on your next project!",
    discoverMore: 'comments',
    jobs: {
        why: 'Why marmelab?',
        presentation: `<p>
            You will learn more than you ever did.
        </p>
        <p>
            No matter where you come from, you will have the opportunity to grow every day and to push your limits, within a team of passionate people eager to help you at all times.
        </p>
        <p>
            You will be working on awesome products: the ones that our customers imagine to shake things up using digital innovation.
        </p>`,
        values: {
            creativity: {
                name: 'Creativity',
            },
            pragmatism: {
                name: 'Pragmatism',
            },
            'knowledge-sharing': {
                name: 'Knowledge Sharing',
            },
            kindness: {
                name: 'Kindness',
            },
            craving: {
                name: 'Craving',
            },
        },
        team: {
            life: 'Life at Marmelab',
            content:
                "Who will you work with? Are they mean? Are they going to eat you? We can't lie about that, because the answer is on Internet.",
        },
        projects: {
            title: 'Projects',
            intro: `
            <p>
                We choose our projects carefully. We love when customers come with an idea full of risks and uncertainties. That's where we make the best of our expertise and work methods. Between two innovation projects opportunities, we choose the one that can make the world a better place.
            </p>
            <p>
                The customer uncertainties can be technical, related to a new usage or a new business model. We use technology to lift these uncertainties through iterative experimentation, Lean Startup style. The byproduct is a web or mobile app, proven valuable by end user usage metrics.
            </p>
            `,
            cloud1859: {
                name: '1859 Cloud',
                description:
                    'This FinTech wanted to validate a bold business model combining anonymity and gamification. We developed a mobile application to prove them right.',
            },
            'react-admin': {
                name: 'React Admin',
                description:
                    "We've developed countless administration panels over the years. Based on what we learned in the process, we built a generic admin framework for the web, using react, redux, and material ui",
            },
            inist: {
                name: 'CNRS Lodex',
                description:
                    'Scientists produce a massive amount of documentation. We helped the French research institute CNRS to publish it on the semantic web by developping a CMS for librarians.',
            },
            arte: {
                name: 'Arte.tv',
                description:
                    'A dedicated team of Marmelab developers has rebuilt the Arte Replay frontend from scratch and still maintains it, using full-stack JS technologies like React.js, Node.js, and GraphQL.',
            },
            amnesty: {
                name: 'Amnesty International',
                description:
                    'How to leverage mobile devices to help save lives? AIF had the intuition that they could engage new supporters to send an email, in their name, to the authorities responsible for human rights violations.',
            },
            link: 'View our project',
        },
        perks: {
            title: 'Perks',
            hackday: {
                name: 'Hack days',
                description: '2 days off every month to learn and experiment',
            },
            conferences: {
                name: 'Conferences',
                description:
                    'Several conferences every year, as attendee or speaker',
            },
            laptop: {
                name: 'The laptop you like',
                description: 'Pick the tools you want to work with every day',
            },
            'open-source': {
                name: 'Open Source',
                description:
                    'We lead or contribute to many open source projects',
            },
            salary: {
                name: 'Competitive Salary',
                description:
                    "We pay well because that's what your talent is worth",
            },
            coaching: {
                name: 'Coaching',
                description:
                    'Seasoned architects mentor you to technical excellence',
            },
            homeOffice: {
                name: 'Home Office',
                description:
                    'Our office in Nancy, your home office: you choose.',
            },
        },
        process: {
            title: 'Our recruitment process',
            contact: {
                name: 'Say Hello',
                description:
                    'Send us your resume and motivations by email, or come and see us at 4 rue Girardet in Nancy.',
            },
            'technical-review': {
                name: 'Get an interview',
                description:
                    "Meet our CEO during a long interview. He'll assess your skills, values, and motivation.",
            },
            'team-fit-review': {
                name: 'Meet the team',
                description:
                    "You're going to work with a team. We want to make sure you get along well, so we'll invite you to share a coffee or a beer with the entire team.",
            },
            contract: 'Sign your contract',
            integration: {
                name: 'Agile Onboarding',
                description:
                    "You'll have 5 weeks to ramp up with our tech and process stack. We'll prepare 5 tech challenges for you to learn by experimentation.",
                link: 'Read more on our integration process',
            },
        },
        apply: {
            title: 'Do you picture yourself at marmelab?',
            jobs: 'Here are the open positions.',
            developer: { name: 'Developer' },
            facilitator: { name: 'Facilitator' },
            link: 'Read more',
            other: `
            <p>Come with your own ideas, skills, experience and will to share.</p>`,
        },
        developer: {
            title: 'Become a Marmelab Developer',
            intro: `
            <p>
                At marmelab, developers don't just code. They transform the ideas of our customers into actual products. To do so, they use cutting-edge web technology and agile methodologies. Developers have the technical responsibility of the products they build. Oh, and we don't have project managers, so developers are in constant touch with customers without intermediaries.
            </p>
            `,
            stack: {
                title: 'Cutting-edge Stack',
                intro: `
                <p>
                    We do our best to choose the best tool for our customer's job. Today, we tend to prefer full-stack JavaScript, but we also have projects in Symfony, Python and Go.
                </p>
                <p>
                    We follow the evolution of the web stack very seriously. We dedicate 10% of our time to research and development, during which we test new approaches, languages, or libraries. When those new technologies actually bring an advantage to our customers projects, we do not hesitate to use them in production. Using this approach, we started using <a href="/blog/2013/02/13/lancement-de-cloudscreener-le-comparateur-d-offre-cloud-pour-les-pme.html">Node.js in 2012</a>, <a href="/blog/2013/07/01/building-sophisticated-webapps-for-mobile-a-bumpy-ride.html">d3.js in 2013</a>, <a href="/blog/2015/05/18/setup-webpack-for-es6-react-application-with-sass.html">React.js in 2015</a>, <a href="/blog/2016/12/16/tobaccobot.html">Serverless in 2016</a>, <a href="/en/blog/#graphql">GraphQL in 2017</a>, <a href="/blog/2018/10/18/react-admin-2-4.html">TypeScript in 2018</a>, and it's only the beginning!
                </p>
                <p>
                    If you're passionate about web & mobile development, Marmelab will be a great opportunity to level up.
                </p>
                `,
                'react-redux': {
                    name: 'React & Redux',
                },
                nodejs: {
                    name: 'Node.js',
                },
                graphql: {
                    name: 'GraphQL',
                },
                postgresql: {
                    name: 'PostgreSQL',
                },
                aws: {
                    name: 'AWS',
                },
            },
            'agility-and-communication': {
                title: 'Agility And Communication',
                intro: `
                <p>
                    Backed by agile methodologies (SCRUM, Kanban, Lean Startup), we focus on the end user value to design and build features. We are directly and constantly communicating with our customers and their domain specialists.
                </p>
                <p>
                    We develop in an iterative manner, in two weeks sprints. We deliver to production continuously, and collect domain specific metrics from end users. Customers prioritize the work every 2 weeks, based on the feedback from earlier sprints.
                    We design each new feature in collaboration with our customers, so they can confront their vision to what the web stack can offer. We look for shortcuts to build a better product faster. We refactor continuously to avoid technical debt.
                </p>
                <p>
                    We talk to the customer Product Owner every morning in a short video conference. And at the end of every sprint, we meet in person for a demo and retrospective.
                    Customers are always aware of the work we do and the problems we solve. This constant communication helps us avoid waste, and the nasty tunnel effect. Customers see us as partners more than contractors.
                </p>
                `,
            },
            ownership: {
                title: 'Shared Responsibility and Autonomy',
                intro: `
                <p>
                    At Marmelab, developers propose their own solutions to the customers needs. They choose the technologies themselves, making sure their are a good fit for the customers context. We choose standard, durable, and fun to use solutions. When we hand over the code to our customers development team, we make sure they become autonomous.
                </p>
                <p>
                    We make decisions as a team, and we share the responsibilities. This autonomy promotes a deep involvement in our customers projects. It makes us have a strong impact on the direction they take.
                </p>
                <p>
                    When we make architecture decisions, we tend to document our key learnings in our blog, see for yourself.
                </p>
                `,
                redux: {
                    name: 'Should we always use redux?',
                },
                'slider-poll': {
                    name: 'Progressive migration from Angular to React',
                },
                accessibility: {
                    name: 'How to automate accessibility tests?',
                },
            },
            lookout: {
                title: 'Research And Development',
                intro: `
                <p>
                    At marmelab, we keep ourselves up to date with web and mobiles technologies. We regularly attend Tech / UX / Agile / Product conferences - it's part of the job description.
                </p>
                <p>
                    We constantly look for tools that help us solve our customers problems faster, or address a complex use case in a simple way. We experiment these tools early on, so we can know them when a customer needs them. We share what we learn in the open, because we learn a lot from other people doing the same.
                </p>
                <p>
                    We also regularly go to conferences as speakers. If a particular subject catches your attention, marmelab will invite you to talk about it publicly.
                </p>
                `,
                conferences: {
                    blend: {
                        name: 'Blend Web Mix',
                    },
                    devfest: {
                        name: 'DevFest Nantes',
                    },
                    dotai: {
                        name: 'dot AI',
                    },
                },
                intro2: `
                <p>
                    We organise a <i>hackday</i> every two weeks.
                    Marmelab employees choose a technical subject of interest, and work on it the whole day. It doesn't have to be related to the project they're working on.
                </p>
                <p>
                    It can be a new language to discover, a tool to build, a methodology to learn about. The goal is to learn something new, and to leverage this learning for the others, with a blog post or an open-source project.
                </p>
                `,
                hackdays: {
                    detox: {
                        name: 'Detox : a testing framework',
                    },
                    'memory-leaks': {
                        name: 'How to track memory leaks?',
                    },
                    'functional-programming': {
                        name: "What's functional programming?",
                    },
                },
            },
            apply: {
                title: 'Do you picture yourself as a marmelab developer?',
                intro: `
                <p>
                    The main skill we're looking for is the love for work well done. No matter what programming language you master. You will learn, by our side, the current technologies and their best practices.
                </p>
                <p>
                    We're looking for more than just a particular skill, we're looking for a deep experience in web development and the ability to use this experience in new customer projects.
                </p>

                <p>
                    We are looking for seasoned developers, having <strong>at least 5 years of experience</strong>. You should master:
                </p>

                <ul>
                    <li>JS or PHP7 or .Net/C# or Java/J2EE or Ruby or Python, in production and on a large codebase</li>
                    <li>A MVC framework (Express, Symfony, MVC.Net, Sprint, RoR, Django)</li>
                    <li>API-centric architectures (REST, HTTP)</li>
                    <li>Design patterns</li>
                    <li>Automated tests</li>
                    <li>Frontend tech (HTML, CSS, animations, accessibility)</li>
                    <li>At least one relational database (PostgreSQL, MySQL, MSSQL, Oracle)</li>
                    <li>At least one NoSQL database (ElasticSearch, Redis, MongoDB, Cassandra)</li>
                    <li>Linux</li>
                    <li>Performance optimisation (profiling, HTTP lifecycle, memory management)</li>
                    <li>Collaboration with other developers (pull requests, code reviews, continuous integration)</li>
                    <li>French and English</li>
                    <li>Agile methodologies (SCRUM or KANBAN or Lean Startup)
                </li></ul>
                `,
                outro: 'Ready to take up the challenge?',
            },
        },
        facilitator: {
            title: 'Become a marmelab facilitator',
            intro: `
            <p>
                At marmelab, facilitators boost a product team to make it more efficient, taking into account the human side of this kind of challenge. To that end, they use different methods taken from on entrepreneurship, industry, software development, and coaching.
            </p>
            <p>The facilitator is a very versatile position filling many different roles.</p>
            `,
            inception: {
                title: 'Project setup',
                intro: `
                <p>
                    During project setup, we prepare everything that is needed so that the developers can start their first sprint, and we lay a vision of what can come about on the next ones.
                    We've described this setup methodology in detail in <a href="/blog/2017/09/29/project-initialization.html">a blog post</a>.
                </p>
                <p>
                    This is a team work with the product owner, the domain specialists, and the end users (when possible). We take advantage of many different methods, and we choose the best one depending on the customer context.
                </p>
                `,
                'lean-startup': {
                    name: 'Lean Startup',
                    description:
                        'Assess the viability of a product or business model by iterating on the most impactful hypotheses.',
                },
                'design-thinking': {
                    name: 'Design Thinking',
                    description:
                        'Generate user-centric ideas : Empathize, Define, Ideate, Prototype and Test',
                },
                scrum: {
                    name: 'Scrum',
                    description:
                        'Transform ideas in story maps, then in user stories that developers can build',
                },
            },
            'project-management': {
                title: 'Project Management',
                intro: `
                <p>
                    As soon as the development starts, the facilitator role looks more like a Scrum Master. On the product side, we use the same tools as for the project setup, in order to prepare the backlog for the subsequent sprints.
                </p>
                <p>
                    The mission of the facilitator is also to get the best out of the team, altogether. He does this by facilitating agile rituals (daily, demonstration, retrospective, planning), using the Scrum framework, but overall, by focusing on pragmatism and empathy.
                </p>
                `,
                'identify-impediments': {
                    name: 'Help Identify Impediments',
                    description:
                        'Facilitate retrospective by instilling a trustful environment',
                },
                'self-organization': {
                    name: 'Promote Self-organisation',
                    description:
                        'Push the team to communicate and work together by instinct',
                },
                delivery: {
                    name: 'Incite Regular Delivery',
                    description: 'Reduce waste with short feedback cycles',
                },
                value: {
                    name: 'Put Focus on Value',
                    description:
                        'Make the end user a priority of every discussion',
                },
            },
            'continuous-improvement': {
                title: 'Continuous Improvement',
                intro: `
                <p>
                    When not in a customer project, the facilitator also helps with the global organisation of the company, just like he does with a project team.
                </p>
                <p>
                    We often compare the issues we have on various customer projects, and how efficient our solutions were. We organise knowledge sharing workshops, with open space technology, or more targeted to a particular problem.
                    Just like developers, it's very important to experiment with new methodologies, to get inspiration from our peers, and to share our progress.
                </p>
                <p>
                    In addition, when the company stumbles upon everyday life problems, the facilitator use collective intelligence to find innovative solutions. From hack days organisation to onboarding process improvement, every subject can be improved.
                </p>
                `,
                'knowledge-sharing': {
                    name: 'Organise Knowledge Sharing',
                    description:
                        'BBLs, open space technology days, more private discussions',
                },
                lookout: {
                    name: 'Research and Development',
                    description:
                        'Read, go to conferences, read, chat with peers, experiment during projects',
                },
            },
            apply: {
                title: 'Do you picture yourself as a facilitator?',
                intro:
                    'The position requires to be interested by a broad variety of subjects. Here are the fundamental skills you need for that job:',
                softSkills: {
                    title: 'Soft Skills',
                    content: `
                    <ul>
                        <li>Good communication</li>
                        <li>Empathetic</li>
                        <li>Pragmatic</li>
                        <li>Curious</li>
                        <li>Enthusiastic</li>
                        <li>Playful</li>
                    </ul>
                    `,
                },
                hardSkills: {
                    title: 'Hard Skills',
                    content: `
                    <ul>
                        <li>Deep knowledge of project and product agility</li>
                        <li>Scrum, Kanban, Lean Startup</li>
                        <li>User-centric thinking</li>
                        <li>Experience in large scale web applications</li>
                        <li>Deep knowledge of the developer job (a past experience as a web developer is a bonus)</li>
                        <li>Basic knowledge of user experience</li>
                    </ul>
                    `,
                },
                outro: 'Ready to take up the challenge?',
            },
        },
    },
    footer: {
        copyrights: 'All rights reserved.',
        twitter: "Marmelab's Twitter account",
        github: "Marmelab's Github account",
        linkedin: "Marmelab's LinkedIn account",
    },
    locale: 'EN',
    setLocale: 'Set language to %{code}',
    share: {
        text: 'Did you like this article? Share it!',
        facebook: 'Share on Facebook',
        twitter: 'Share on Twitter',
        linkedIn: 'Share on LinkedIn',
        more: 'More options',
    },
    author: {
        twitter: "%{author}'s Twitter account",
        github: "%{author}'s Github account",
    },
    modal: {
        close: 'Close the modal',
    },
    legal: {
        title: 'Legal Mentions',
        editor: {
            title: 'Editor',
            company: 'Company',
            capital: 'Capital',
            registrationNumber: 'Registration Number',
            VATNumber: 'European VAT Number',
            address: 'Address',
            emailAddress: 'Email address',
            publishingDirector: 'Publishing director',
        },
        host: {
            title: 'Hosting',
            provider: 'Provider',
            address: 'Address',
            phoneNumber: 'Phone Number',
        },
        privacy: {
            title: 'Privacy',
            content: `
            <p>
                Because we care about your privacy, this website
                is fully accessible without providing any
                personal data.
            </p>
            <p>
                We use our own self-hosted tracking solution to collect
                some raw metrics like page views, device type (mobile, desktop, etc.) or country.
                We don't track any personal information about our visitors.
                We count on you getting in touch with
                us instead of stalking you.
            </p>
            <p>
                Our commenting platform - commento - is <a href="https://commento.io/privacy">privacy focused</a>.
            </p>
            <p>
                We sometimes have embedded content from external
                websites that might track you.
                Don't worry, we don't load it straightaway and ask for your consent first.
            </p>
            <p>
                The only information we save to your browser helps
                remember your preferred language and your external resources loading preferences.
            </p>
            `,
        },
    },
};
